import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Div100vh from 'react-div-100vh';

import { PoopButton } from './components/PoopButton';

import styles from './App.module.css';

const msToTimeString = (s: number) => {
  const pad = (n: number, z = 2) => `00${n}`.slice(-z);
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;
  return `${pad(hrs)}:${pad(mins)}:${pad(secs)}`;
};

export const App = () => {
  const [pressedTime, setPressedTime] = useState<Date>();
  const [duration, setDuration] = useState('10:01');
  const [globalPoopers, setGlobalPoopers] = useState(0);
  const poopers = globalPoopers + (pressedTime ? 1 : 0);

  useEffect(() => {
    const touchMove = (event: TouchEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };
    document.getElementById('root')?.addEventListener('touchmove', touchMove);
    return () => {
      document
        .getElementById('root')
        ?.removeEventListener('touchmove', touchMove);
    };
  }, []);

  useEffect(() => {
    if (!pressedTime) {
      setDuration(msToTimeString(0));
      return;
    }

    const interval = setInterval(() => {
      if (pressedTime) {
        setDuration(msToTimeString(Date.now() - pressedTime.getTime()));
      }
    }, 1000 / 60);

    return () => {
      clearInterval(interval);
    };
  }, [pressedTime]);

  const pressedClass = {
    [styles.pressed]: Boolean(pressedTime),
  };

  return (
    <Div100vh className={styles.app}>
      <header className={styles.header}>
        <div className={classNames(styles.poopers, pressedClass)}>
          <div className={styles.infoTitle}>Global Poopers</div>
          <div className={styles.infoData}>{poopers}</div>
          {/* {poopers === 1 && Boolean(pressedTime) && <div>Just you!</div>} */}
        </div>
        <div className={classNames(styles.duration, pressedClass)}>
          <div className={styles.infoTitle}>Poop Duration</div>
          <div className={styles.infoData}>{duration}</div>
        </div>
      </header>
      <div className={styles.body}>
        {!pressedTime && (
          <div className={styles.instructions}>
            Press and *hold for the duration of your dump
            <div className={styles.subinstructions}>
              * Poop Pub recommends letting go of the poop button if an
              emergency arises that would require your button pressing hand
              (e.g. your bum needs itched in a difficult-to-reach area)
            </div>
          </div>
        )}
      </div>
      <PoopButton
        onPressDown={() => setPressedTime(new Date())}
        onPressUp={() => setPressedTime(undefined)}
      />
    </Div100vh>
  );
};
