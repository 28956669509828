import classNames from 'classnames';
import { FC, useState } from 'react';

import styles from './index.module.css';

type PoopButtonTypes = {
  onPressDown: () => void;
  onPressUp: () => void;
};

export const PoopButton: FC<PoopButtonTypes> = ({ onPressDown, onPressUp }) => {
  const [pressed, setPressed] = useState(false);

  const handleDown = () => {
    setPressed(true);
    onPressDown();
  };
  const handleUp = () => {
    setPressed(false);
    onPressUp();
  };

  const pressedClass = { [styles.pressed]: pressed };

  return (
    <div
      className={classNames(styles.poop, pressedClass)}
      onTouchStart={handleDown}
      onMouseDown={handleDown}
      onTouchEnd={handleUp}
      onMouseUp={handleUp}
    >
      <div className={classNames(styles.poop1, pressedClass)}>
        <div className={classNames(styles.poop2, pressedClass)}>
          <div className={classNames(styles.poop3, pressedClass)}>
            {pressed ? 'ahhhhhhh....' : 'just let it out'}
          </div>
        </div>
      </div>
    </div>
  );
};
